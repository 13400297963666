<template>
  <v-card>
    <v-row v-if="idlist">
      <v-col cols="12">
        <div class="d-flex">
          <v-breadcrumbs :items="itemsBreadcrumbs" divider="/"></v-breadcrumbs>
        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <div id="surveyCreator"></div>
  </v-card>
</template>

<script>
import * as SurveyCreator from 'survey-creator-knockout'
import * as SurveyKo from 'survey-core'
import * as widgets from 'surveyjs-widgets'
import 'survey-core/defaultV2.min.css'
import 'survey-creator-core/survey-creator-core.min.css'
import { init as customWidget } from '@/components/surveyjs/customwidget'
import eFormMixin from '../../mixins/eFormMixin'
import contactMixin from '../../mixins/contactMixin'
import {
  mdiFormSelect,
  mdiInformationOutline,
  mdiPlus,
  mdiRadioTower,
  mdiCounter,
  mdiImageFilterCenterFocusWeak,
  mdiPoundBoxOutline,
  mdiPlusCircle,
  mdiDragVertical,
  mdiDeleteOutline,
} from '@mdi/js'

// widgets.select2(SurveyKo)
// widgets.inputmask(SurveyKo)
// widgets.jquerybarrating(SurveyKo)
// widgets.jqueryuidatepicker(SurveyKo)
// widgets.nouislider(SurveyKo)
// widgets.select2tagbox(SurveyKo)
// widgets.sortablejs(SurveyKo)
// widgets.ckeditor(SurveyKo)
// widgets.autocomplete(SurveyKo)
// widgets.bootstrapslider(SurveyKo)
// customWidget(SurveyKo)

SurveyCreator.SurveyQuestionEditorDefinition.definition = {}

const creatorOptions = {
  showEmbeddedSurveyTab: false,
  showTestSurveyTab: false,
  showOptions: false,
  showLogicTab: false,
  showJSONEditorTab: false,
  questionTypes: [
    'text',
    // 'checkbox',
    // 'radiogroup',
    // 'dropdown',
    // 'comment',
    // 'rating',
    // 'imagepicker',
    // 'boolean',
    // 'html',
    // 'expression',
  ],
  pageEditMode: 'single',
  showTitlesInExpressions: false,
  allowEditExpressionsInTextEditor: false,
  showSurveyTitle: false,
}

export default {
  name: 'survey-creator',
  mixins: [eFormMixin, contactMixin],
  setup() {
    return {
      icons: {
        mdiFormSelect,
        mdiInformationOutline,
        mdiPlus,
        mdiRadioTower,
        mdiCounter,
        mdiImageFilterCenterFocusWeak,
        mdiPoundBoxOutline,
        mdiPlusCircle,
        mdiDragVertical,
        mdiDeleteOutline,
      },
    }
  },
  data() {
    return {
      idlist: '',
      columns: [],
      // listDraggable: [],
      // dialogAlert: false,
      // alertText: '',
      currentList: null,
      createSuccess: false,
      surveyCreator: '',
      // currentEForm: {},
      // eFormSelectedFields: [],
      // availableFields: [],
      itemsBreadcrumbs: [
        {
          text: 'Contact',
          disabled: false,
          link: true,
          exact: true,
          to: {
            name: 'contacts-group-list',
          },
        },
      ],
      maxVisibleIndex: 0,
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
  async mounted() {
    this.idlist = this.$route.params.idlist
    this.loadDataColumn()
    this.loadSetting()
  },
  methods: {
    async loadDataColumn() {
      const self = this
      const { data } = await this.$store.dispatch('global/getContactLists', {
        sub_id: this.user.sub_id,
        token: this.user.token,
      })

      //ambil data column dari db atau defaultnya
      this.currentList = data.find(contact => contact.list_id === this.$route.params.idlist)
      this.columns = this.currentList.columns.filter(column => column.name !==
      'phone_number_show');

      const columnsToShow = {}
      columnsToShow.elements = this.columns.map(column => {
        column.title = column.text
        column.visible = column.show
        column.inputType = column.type
        return column
      })
      columnsToShow.name = 'Default Column'
      columnsToShow.description = 'Field to show to the table'
      columnsToShow.title = 'Default Column'

      this.surveyCreator = new SurveyCreator.SurveyCreator(creatorOptions)
      this.surveyCreator.text = JSON.stringify({ pages: [columnsToShow] })

      this.surveyCreator.onElementAllowOperations.add(function (_, options) {
        const nm = options.obj?.name
        const ty = options.obj?.getType()
        const pg = options.obj?.page?.name

        if (
          (pg === 'Default Column' && nm === 'phone_number') ||
          nm === 'name' ||
          nm === 'created_by' ||
          nm === 'updated_by' ||
          nm === 'created_at' ||
          nm === 'updated_at'
        ) {
          options.allowDelete = false
          options.allowChangeType = false
          options.allowCopy = false
        } else {
          options.allowChangeType = false
          options.allowCopy = false
        }

        SurveyCreator.localization.getLocale('en').pe.defaultValue = 'Default Value'
        SurveyCreator.localization.getLocale('en').pe.title = 'Column Name'
        SurveyCreator.localization.getLocale('en').ed.addNewQuestion = 'Add New Column'
      })

      this.surveyCreator.onShowingProperty.add(function (sender, options) {
        const column = options.obj?.name;
        const property = options.property.name;
        if ( column === 'phone_number' ||
          column === 'name' ||
          column === 'created_by' ||
          column === 'updated_by' ||
          column === 'created_at' ||
          column === 'updated_at'
        ) {
          if (property === 'visible' || property === 'defaultValue' || property === 'required') {
            options.property.showProperty = true;
            options.canShow = true;
          } else {
            options.canShow = false;
          }
        } else {
          if (property === 'visible' || property === 'defaultValue' || property === 'required' || property === 'title') {
            options.property.showProperty = true;
            options.canShow = true;
          } else {
            options.canShow = false;
          }
        }
      })

      // this.surveyCreator.inplaceEditForValues = true
      // this.surveyCreator.onSetPropertyEditorOptions.add(function (sender, options) {
      //   options.editorOptions.showTextView = false
      // })

      this.surveyCreator.toolbarItems.splice(2, 4)
      this.surveyCreator.toolbarItems.push(
        new SurveyKo.Action({
          id: 'custom-save',
          visible: true,
          title: 'Save Config',
          action: function () {
            self.saveConfig()
          },
        }),
      )
      this.surveyCreator.toolbarItems.push(
        new SurveyKo.Action({
          id: 'cancel',
          visible: true,
          title: 'Cancel',
          action: function () {
            self.closeDialogConfig()
          },
        }),
      )
      this.surveyCreator.render('surveyCreator')
    },
    closeDialogConfig() {
      this.$router.push({ name: 'contacts-list-custom', params: { idlist: this.$route.params.idlist } })
    },
    async saveConfig() {
      this.loading = true
      const columnsResult = JSON.parse(this.surveyCreator.text)

      const columnToSave = columnsResult.elements.map(column => {
        const findColumn = this.columns.find(col => column.name === col.name)
        if (!findColumn) {
          const columnName = column.title.trim().replace(/\s\s+/g, '_').toLowerCase();
          return {
            name: columnName,
            show: column.visible === undefined || column.visible ? true : column.visible,
            text: column.title,
            type: 'text',
            align: 'left',
            field: columnName,
            label: column.title,
            value: columnName,
            editable: true,
            showable: true,
            sortable: true,
            deleteable: true,
            formatDate: '',
            isRequired: !column.isRequired ? false : true,
            defaultValue: column.defaultValue ? column.defaultValue : '',
            order_number: this.columns.length + 1,
          }
        } else {
          if (!findColumn.editable && !findColumn.deleteable) {
            findColumn.show = column.visible === undefined || column.visible ? true : column.visible;
            findColumn.isRequired = !column.isRequired ? false : true;
            return findColumn;
          } else {
            findColumn.show = column.visible === undefined || column.visible ? true : column.visible
            findColumn.text = column.title
            findColumn.defaultValue = column.defaultValue ? column.defaultValue : ''
            findColumn.isRequired = !column.isRequired ? false : true
            return findColumn
          }
        }
      })

      // //console.log(columnToSave, 'data');
      const res = await this.saveConfigToDb(columnToSave, this.columns)

      if (res.error) {
        this.showAlert(res.errorMessage)
      } else {
        // this.saveSurvey(JSON.parse(this.surveyCreator.text))
        this.$router.push({ name: 'contacts-list-custom', params: { idlist: this.$route.params.idlist } })
      }
      this.loading = false
    },
    async saveSurvey(survey) {
      if (JSON.stringify(survey) === JSON.stringify({ pages: [{ name: 'page1' }] })) {
        this.alertDialog = true
        this.alertTitle = 'Oops...'
        this.alertText = 'survey is empty'
      } else if (survey.title) {
        // const dataForm = this.$store.getters['link/getSaveDataEform'];
        const data = {
          title: survey.title,
          description: survey.description,
          facebook_pixel_id: this.currentEForm.facebook_pixel_id,
          google_tag_manager_id: this.currentEForm.google_tag_manager_id,
          eform_id: this.currentEForm._id,
          contactList: this.currentEForm.contactList,
          addToSurvey: this.currentEForm.addToSurvey,
          selectedFields: this.eFormSelectedFields,
          channel: this.currentEForm.channel,
          survey,
        }

        await this.editEForm(data)
        if (this.createSuccess) {
          this.$router.push({ name: 'contacts-list-custom', params: { idlist: this.$route.params.idlist } })
        }
      } else {
        this.alertDialog = true
        this.alertTitle = 'Oops...'
        this.alertText = this.$t('eform.titleEmpty')
      }
    },
    async loadSetting() {
      const contactList = await this.$store.dispatch('eOrdering/getContactListCheck', {
        contact: this.idlist,
      })
      if (contactList.status) {
        this.itemsBreadcrumbs.push({
          text: contactList.data.list_name,
          disabled: false,
          link: true,
          exact: true,
          to: {
            name: 'contacts-list-custom',
            params: { idlist: this.idlist },
          },
        })
        this.itemsBreadcrumbs.push({
          text: 'Config',
          disabled: true,
        })
      }
    },
  },
}
</script>
<style>
#surveyCreator {
  height: 100vh;
}
.svc-creator .svc-full-container .svc-creator__banner {
  display: none !important;
}
</style>
